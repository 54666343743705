import React from 'react';
import moment from 'moment-timezone';
import sortBy from 'lodash/sortBy';
import capitalize from 'lodash/capitalize';
import PropTypes from 'prop-types';

import Tags from 'hsi/components/Mention/Tags';

import useStyles from './styles';

const dateFormat = 'MMM DD, YYYY, H:mm:ss';

export const parseDate = (datum, tz) =>
    tz ? moment(datum).tz(tz).format(dateFormat) : moment(datum).format(dateFormat);

export const parseEmotion = (datum) =>
    datum
        ? capitalize(
              sortBy(
                  datum.filter((d) => d.classifierId === 'emotions'),
                  ['confidence'],
              )[0]?.name,
          )
        : null;

export const MentionTableTags = ({tags}) => {
    const classes = useStyles();

    return (
        <Tags
            chipClassName={classes.tagChip}
            className={classes.tags}
            editable={false}
            size="small"
            tags={tags}
        />
    );
};

MentionTableTags.propTypes = {
    tags: PropTypes.array,
};

export const parseTags = (tags) => <MentionTableTags tags={tags} />;

export const columnsConfig = {
    date: {
        dataKeys: ['date', 'fullname'],
        parsers: {date: parseDate},
        width: 15,
    },
    platform: {
        dataKeys: ['pageType'],
        filtersKey: 'pageType',
        parsers: {pageType: capitalize},
        width: 15,
    },
    content: {
        dataKeys: ['snippet', 'tags'],
        parsers: {tags: parseTags},
        width: 30,
    },
    reach: {
        dataKeys: ['reachEstimate'],
        width: 6,
    },
    country: {
        dataKeys: ['country'],
        width: 8,
    },
    gender: {
        dataKeys: ['gender'],
        filtersKey: 'gender',
        parsers: {gender: capitalize},
        width: 8,
    },
    sentiment: {
        dataKeys: ['sentiment'],
        filtersKey: 'sentiment',
        parsers: {sentiment: capitalize},
        width: 8,
    },
    emotion: {
        dataKeys: ['classifications'],
        filtersKey: 'classifications',
        parsers: {classifications: parseEmotion},
        width: 8,
    },
};

export const parseColumns = (tableLayout) => {
    return tableLayout
        .map((col) => {
            const config = columnsConfig[col.id];
            if (!config) return null;
            return {id: col.id, ...config};
        })
        .filter((col) => !!col);
};
